<template>
    <div>
        <nl-loader v-if="preloading" :show="true" />

        <div v-else>
            <div v-if="!orders" class="">
                <b-notification type="is-warning is-light" aria-close-label="Close notification" role="alert">
                    {{ $t('partenaire-site.orders.no-orders') }}
                </b-notification>
            </div>

            <ButtonRouterTo to="/partner/sites" label="Retour aux sites" size="is-small" icon-left="backward" />
            <div class="box">
                <SiteOrdersTable
                    :data="orders"
                    :orders-length="orders.data.length"
                    :total-earnings="getTotalEarnings()"
                    @loadAsyncData="loadAsyncData"
                />
            </div>
        </div>
        <Toaster ref="toaster" />
    </div>
</template>

<script>
    import Toaster from '@/components/UI/Toaster'
    import ButtonRouterTo from '../../components/UI/Button/ButtonRouterTo'

    export default {
        name: 'SitesOrders',
        title: 'partenaire-commandes.title',
        components: {
            ButtonRouterTo,
            Toaster
        },
        data: function () {
            return {
                site: null,
                orders: [],

                //UI
                preloading: false
            }
        },
        created() {
            this.getData()
        },
        mounted: function () {},
        methods: {
            getData: function () {
                this.preloading = true

                var site = this.axios
                    .get(`/sites/orders/${this.$route.query.id}`)
                    .then(response => {
                        this.site = response.data.site
                        this.orders = response.data.orders
                    })
                    .catch(error => {
                        this.$refs.toaster.fire('error', error.response.data.message)
                    })

                Promise.all([site]).then(() => {
                    this.preloading = false
                })
            },

            loadAsyncData(data) {
                let params = {
                    page: data.page,
                    sort: data.sortOrder === 'desc' ? '-' + data.sortField : data.sortField,
                    per_page: data.per_page
                }

                if (data.filters && data.filters.length > 0) {
                    data.filters.forEach(element => {
                        params[`filter[${element.key}]`] = element.value
                    })
                }

                this.axios
                    .get(`/sites/orders/${this.$route.query.id}`, { params })
                    .then(response => {
                        this.site = response.data.site
                        this.orders = response.data.orders
                    })
                    .catch(error => {
                        this.$refs.toaster.fire('error', error.response.data.message)
                    })
            },

            getTotalEarnings() {
                return this.orders.data
                    .reduce((a, b) => {
                        if (b.statut !== 'termine') {
                            return a
                        }

                        return a + b.earning
                    }, 0)
                    .toFixed(2)
            }
        }
    }
</script>
